import React from 'react';
import 'styled-components/macro';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';

export const OffersContainer = ({ heading = 'Hot offers', children }) => (
  <>
    {heading && (
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
        {heading}
      </Heading4>
    )}
    <div
      css={`
        > div {
          margin: 0 auto 20px auto;
        }

        @media (min-width: 992px) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > div {
            flex: 0 48%;
            margin: 0 0 20px 0;
          }

          > div:nth-child(even) {
            margin-left: 20px;
          }
        }
      `}
    >
      {children}
    </div>
  </>
);
