import React from 'react';
import 'styled-components/macro';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import SvgInline from '@latitude/svg-inline';
import { ALIGN, BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import ReactHTMLParser from 'react-html-parser';

export const PlanTypes = ({
  heading = 'Our Interest Free Payment Plans explained',
  types = [
    PLAN_TYPE_INTEREST_FREE,
    PLAN_TYPE_INSTALMENT_IF,
    PLAN_TYPE_INTEREST_FREE_DEFFERRED
  ]
}) => (
  <Box id="plan-types">
    <Box.Section backgroundColor={COLOR.GREY6}>
      {heading && (
        <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
          {heading}
        </Heading4>
      )}
      <div
        css={`
          @media (min-width: 375px) {
            width: 350px;
          }
          margin: 0 auto;

          @media (min-width: ${BREAKPOINT.XL}) {
            margin: 0;
            width: auto;
            display: flex;
            justify-content: center;
          }
        `}
      >
        {types.map(
          ({
            icon,
            title,
            subTitle,
            description,
            color,
            colorDarker,
            href,
            linkText,
            onClick
          }) => (
            <div
              key={title}
              css={`
                @media (min-width: ${BREAKPOINT.XL}) {
                  &:not(:last-child) {
                    margin-right: 30px;
                  }
                }
              `}
            >
              <div
                css={`
                  max-width: 350px;
                  background-color: #ffffff;
                  padding: 15px;
                  margin: 80px 0 20px 0;
                  border-top: 8px solid ${colorDarker};
                  padding-bottom: 32px;
                `}
              >
                <div
                  css={`
                    .plan-type-icon {
                      margin: -55px auto 10px auto;
                    }
                  `}
                >
                  <PlanTypeIcon
                    icon={icon}
                    color={color}
                    colorDarker={colorDarker}
                  />
                </div>

                <div
                  css={`
                    height: 32px;
                    /* width: 298px; */
                    color: #000000;
                    font-family: Montserrat;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: -0.53px;
                    line-height: 32px;
                    text-align: center;
                    margin-bottom: 8px;
                  `}
                >
                  {title}
                </div>
                <div
                  css={`
                    display: flex;
                  `}
                >
                  <div
                    css={`
                      height: 360px;
                      width: 100%;
                      color: #000000;
                      font-family: Roboto;
                      font-size: 16px;
                      font-weight: 300;
                      letter-spacing: 0;
                      line-height: 24px;
                    `}
                  >
                    <ul>
                      {ReactHTMLParser(description)}
                      {linkText && (
                        <Link
                          css={`
                            display: inline-block;
                            margin-top: 20px;
                            margin-bottom: 20px;
                          `}
                          href={href}
                          onClick={onClick || undefined}
                        >
                          {linkText}
                        </Link>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </Box.Section>
  </Box>
);

export const PlanTypeIcon = ({ icon, color, colorDarker }) => (
  <div
    className="plan-type-icon"
    css={`
      box-sizing: border-box;
      height: 72px;
      width: 72px;
      border: 8px solid ${colorDarker};
      /* background-color: ${color}; */
      background-color: #fff;
      border-radius: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 56px;
        margin-top: ${icon === 'flexibility' ? '-10px' : 0};
      }

      @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
        (min-width: ${BREAKPOINT.XL}) {
        height: 80px;
        min-width: 80px;
      }
    `}
  >
    <SvgInline name={icon} aria-label={icon} height="56px" />
  </div>
);

export const PLAN_TYPES = [
  {
    icon: 'equal',
    title: 'Instalment interest free',
    description: [
      '<li>Equal payments (instalment) to be paid each month for the duration of the plan</li><li>Paying the instalment payment each month on time will pay off the balance within the interest free period</li><li>Plans vary and are only available at selected retailers, during special promotional periods<a class="link--sup" href="#important-information">*</a></li>'
    ],
    color: '#E8F4FF',
    colorFg: '#fff',
    colorDarker: '#0046aa'
  },
  {
    icon: 'minimum',
    title: 'Interest free',
    description: [
      '<li>A minimum payment is required each month for the duration of the plan</li><li>Paying only the minimum payment will not pay off the balance in full before the expiry of the plan</li><li>Get 6 months interest free for Visa purchases $250 or more<a class="link--sup" href="#important-information">*</a></li><li>Longer terms often available - check in store for their current offer<a class="link--sup" href="#important-information">*</a></li>'
    ],
    color: '#FFEFF7',
    colorDarker: '#FFA9D5'
  },
  {
    icon: 'flexibility',
    title: 'Interest free deferred',
    description: [
      '<li>No minimum payments required during the duration of the plan</li><li>You are welcome to make voluntary payments any time before the end of the plan</li><li>Never pay a cent of interest on your purchase, provided you pay the balance on time and in full before the end of the plan</li><li>There are a range of plans available - please check in store for the finance terms they offer<a class="link--sup" href="#important-information">*</a></li>'
    ],
    color: '#E8F4FF',
    colorDarker: '#82C6FF'
  }
];

export const PLAN_TYPE_INSTALMENT_IF = PLAN_TYPES[0];
export const PLAN_TYPE_INTEREST_FREE = PLAN_TYPES[1];
export const PLAN_TYPE_INTEREST_FREE_DEFFERRED = PLAN_TYPES[2];
