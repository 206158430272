import React from 'react';
import MediaQuery from 'react-responsive';
import 'styled-components/macro';
import {
  AnalyticsLocationProvider,
  AnalyticsContext,
  AnalyticsLocationConsumer,
  trackEvent
} from 'latitude-analytics';
import { ALIGN, COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import { Accordion } from '@latitude/accordion';
import { Heading4 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { LinkBlock } from '@latitude/link-block';
import Image from '../Image/Image';
import { PLAN_TYPES, PlanTypeIcon } from './PlanTypes';

const BaseOfferTile = ({
  children,
  terms,
  trackTermsEventData,
  termsScrollToId
}) => {
  const [analytics] = React.useContext(AnalyticsContext);
  return (
    <div
      className="interest-free-offer-tile"
      css={`
        border: 1px solid ${COLOR.GREY16};
        max-width: 542px;
      `}
    >
      {children}
      {terms && (
        <Accordion
          fgColor="grey-dark"
          css={`
            .accordion__item {
              margin-bottom: 0;
              button {
                padding-left: 24px;
                color: #000000;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 28px;
                font-weight: 500 !important;
              }
            }
          `}
          items={[
            {
              content: <div className="py-3 px-4">{terms}</div>,
              id: 'terms-and-conditions',
              title: 'Terms & Conditions',
              trackEventData: {
                label: trackTermsEventData.label
                  ? trackTermsEventData.label
                  : 'Terms & Conditions',
                location: trackTermsEventData.location
              }
            }
          ]}
        />
      )}
      {termsScrollToId && (
        <AnalyticsLocationConsumer>
          {(location: string) => (
            <LinkBlock
              noBorder
              onClick={() => {
                const element = document.getElementById(termsScrollToId);
                window.scrollTo({
                  top: element.offsetTop - 56,
                  left: 0,
                  behavior: 'smooth'
                });

                trackEvent(analytics, {
                  category: 'button-link',
                  action: 'internal-link',
                  label: 'Terms & Conditions',
                  location
                });
              }}
              className="bg-white"
              chevronDirection="right"
            >
              Terms & Conditions
            </LinkBlock>
          )}
        </AnalyticsLocationConsumer>
      )}
    </div>
  );
};

export const MinimumMonthly = props => (
  <OfferTile {...PLAN_TYPES[0]} {...props} />
);

export const EqualMonthly = props => (
  <OfferTile {...PLAN_TYPES[1]} {...props} />
);

export const Flexible = props => <OfferTile {...PLAN_TYPES[2]} {...props} />;

export const FeatureTile = props => {
  const {
    heading = 'Today’s hottest offer',
    merchantLink,
    mobileImage,
    desktopImage,
    planType,
    pixelTrackingLink
  } = props;

  return (
    <div
      css={`
        .interest-free-offer-tile {
          max-width: 730px;
          margin: 0 auto;
        }
      `}
    >
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
        {heading}
      </Heading4>
      <BaseOfferTile {...props}>
        <Link noStyle href={merchantLink} trackEventData={props.trackEventData}>
          <MediaQuery maxWidth={parseInt(BREAKPOINT.MD) - 1}>
            <Image src={mobileImage} alt={props.trackEventData.label} />
          </MediaQuery>
          <MediaQuery minWidth={BREAKPOINT.MD}>
            <Image src={desktopImage} alt={props.trackEventData.label} />
          </MediaQuery>
        </Link>
        {/* Impression tracking pixel */}
        {pixelTrackingLink && (
          <img
            css={`
              display: none;
            `}
            src={pixelTrackingLink}
            border="0"
            height="1"
            width="1"
            alt="Advertisement"
          />
        )}
        <PlanTypeBanner {...planType} />
      </BaseOfferTile>
    </div>
  );
};

const OfferTile = props => {
  const {
    applicableTo,
    numberOf,
    frequency,
    minFinancedAmount,
    minPurchaseAmount,
    color,
    title
  } = props;

  const location = `Offer: ${title}, ${numberOf} ${frequency}, ${applicableTo}, min $${minFinancedAmount ||
    minPurchaseAmount}`;
  return (
    <AnalyticsLocationProvider location={location}>
      <BaseOfferTile {...props}>
        <div
          css={`
            padding: 10px 16px;
            color: #000000;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 28px;
            background-color: #fff;

            @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
              (min-width: ${BREAKPOINT.XL}) {
              font-size: 24px;
              line-height: 36px;
            }
          `}
        >
          {applicableTo}
        </div>
        <div
          css={`
            background-color: ${color};
            padding: 10px 0;

            @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
              (min-width: ${BREAKPOINT.XL}) {
              padding: 16px 0;
            }
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: center;

              @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                (min-width: ${BREAKPOINT.XL}) {
                padding: 0 25px;
                justify-content: left;
              }
            `}
          >
            <div
              css={`
                color: #000000;
                font-family: Montserrat;
                font-size: 64px;
                font-weight: 600;
                letter-spacing: -1.4px;
                line-height: 56px;
                margin-right: 8px;

                @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                  (min-width: ${BREAKPOINT.XL}) {
                  font-size: 104px;
                  letter-spacing: -2.38px;
                  line-height: 122px;
                  line-height: 90px;
                }
              `}
            >
              {numberOf}
            </div>
            <div>
              <div
                css={`
                  color: #000000;
                  font-family: Montserrat;
                  font-size: 32px;
                  font-weight: 600;
                  letter-spacing: -0.7px;
                  line-height: 36px;

                  @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                    (min-width: ${BREAKPOINT.XL}) {
                    font-size: 40px;
                    letter-spacing: -0.88px;
                    line-height: 48px;
                  }
                `}
              >
                {frequency}
              </div>
              <div
                css={`
                  color: #000000;
                  font-family: Roboto;
                  font-size: 18px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 28px;
                  text-transform: uppercase;

                  @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                    (min-width: ${BREAKPOINT.XL}) {
                    font-size: 24px;
                    line-height: 36px;
                  }
                `}
              >
                Interest free
              </div>
              <div
                css={`
                  color: #000000;
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 20px;
                  text-align: left;
                  margin-bottom: 10px;
                  display: none;

                  @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                    (min-width: ${BREAKPOINT.XL}) {
                    display: block;
                  }
                `}
              >
                {minFinancedAmount
                  ? `Minimum financed amount $${minFinancedAmount}.`
                  : `Minimum purchase $${minPurchaseAmount}.`}
              </div>
            </div>
          </div>
          <div
            css={`
              color: #000000;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0;
              line-height: 20px;
              text-align: center;
              margin-bottom: 10px;
              @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                (min-width: ${BREAKPOINT.XL}) {
                display: none;
              }
            `}
          >
            {minFinancedAmount
              ? `Minimum financed amount $${minFinancedAmount}.`
              : `Minimum purchase $${minPurchaseAmount}.`}
          </div>
          <PlanTypeBanner {...props} />
        </div>
      </BaseOfferTile>
    </AnalyticsLocationProvider>
  );
};

const PlanTypeBanner = props => {
  const { title, subTitle, colorDarker, colorFg } = props;
  return (
    <div
      css={`
        padding-left: 9px;
        display: flex;
      `}
    >
      <PlanTypeIcon {...props} />
      <div
        css={`
          background-color: ${colorDarker};
          margin-left: -36px;
          padding-left: 36px;
          padding-right: 16px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div>
          <div
            css={`
              color: ${colorFg || '#000'};
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: -0.35px;
              line-height: 22px;

              @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                (min-width: ${BREAKPOINT.XL}) {
                font-size: 20px;
                letter-spacing: -0.44px;
                line-height: 24px;
              }

              @media (max-width: 400px) {
                font-size: 0.9em;
              }
            `}
          >
            {title}
            <div
              css={`
                @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                  (min-width: ${BREAKPOINT.XL}) {
                  display: none;
                }
              `}
            >
              repayments
            </div>
          </div>
          <div
            css={`
              color: ${colorFg || '#000'};
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 20px;
              display: none;
              max-width: 270px;

              @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
                (min-width: ${BREAKPOINT.XL}) {
                display: block;
              }
            `}
          >
            {subTitle}
          </div>
        </div>
        <Link
          button="tertiary"
          href="javascript:;"
          onClick={() => {
            const element = document.getElementById('plan-types');
            window.scrollTo({
              top: element.offsetTop - 56,
              left: 0,
              behavior: 'smooth'
            });
          }}
          css={`
            font-weight: 500;
            background-color: transparent !important;
            margin-left: 16px;
            color: ${colorFg || '#000'} !important;
            border: solid 1px ${colorFg || '#000'} !important;
            &:hover {
              background-color: rgba(255, 255, 255, 0.4) !important;
              border-color: ${colorFg || '#000'} !important;
            }

            @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.MD}),
              (min-width: ${BREAKPOINT.XL}), {
              height: 48px;
              width: 144px;
              font-size: 18px;
              line-height: 28px;
            }
            @media (max-width: 400px) {
              font-size: 0.9em;
              width: 97px;
            }
          `}
          // disableDefaultEventTracking
        >
          Plan types
        </Link>
      </div>
    </div>
  );
};
